import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faStore, faCommentAlt} from "@fortawesome/free-solid-svg-icons";
import Cart from './Cart';
import Search from './Search';
import './NavMenu.css';

const NavMenu = (props) => {
    React.useEffect(() => {
    }, [props.cartCount]);

    return <Navbar collapseOnSelect bg="dark" variant="dark" expand="lg" fixed="top" className="fade-from-bottom">
        <Navbar.Brand className="brand-logo" href="#home">
            <img
                src="./images/logo.png"
                // width="30"
                height="100px"
                className="d-inline-block align-top"
                alt="Logo"
            />{' '}
        </Navbar.Brand>
        {/*<div className="input-group col-md-4 navbasket-collapse search-wrap-s"><Search searchCallback={props.searchCallback}/></div>*/}
        <Navbar.Brand className="navbasket-collapse basket-wrap-s" >
            <Nav className="navbar-custom">
                {/*<Nav.Link href="/basket" ><Cart cartCount={props.cartCount}/></Nav.Link>*/}
            </Nav>
        </Navbar.Brand>
        <Navbar.Toggle/>
        <Navbar.Collapse className="justify-content-between">
            <Nav className="navbar-custom">
                <Nav.Link href="#home">MEET SEAN</Nav.Link>
                <Nav.Link href="#transformation">TRANFORMATION</Nav.Link>
                <Nav.Link href="#coaching">COACHING</Nav.Link>
                <Nav.Link href="#posing">POSING</Nav.Link>
                <Nav.Link href="#contact">CONTACT</Nav.Link>
                <Nav.Link href="#insta">INSTAGRAM</Nav.Link>
                {/*<Nav.Link href="/shop">FOOD FOR THOUGHT</Nav.Link>*/}
                {/*<Nav.Link href="/contact">TESTIMONIALS</Nav.Link>*/}
                {/*<Nav.Link href="/workout">HOW CAN I START</Nav.Link>*/}
                {/*<Nav.Link href="/workout">INSTAGRAM</Nav.Link>*/}
                {/*<Nav.Link href="/workout">APP</Nav.Link>*/}
                {/*<Nav.Link href="/programs"><FontAwesomeIcon icon='dumbbell' /> Coaching</Nav.Link>*/}
            </Nav>
        </Navbar.Collapse>
        {/*<div style={{width: '50%'}} className="input-group col-md-4 navbasket-right"><Search searchCallback={props.searchCallback}/></div>*/}
        <Navbar.Brand className="navbasket-right">
            <Nav className="navbar-custom">
                {/*<Nav.Link href="/basket" ><Cart cartCount={props.cartCount}/></Nav.Link>*/}
            </Nav>
        </Navbar.Brand>
    </Navbar>;
}

export default NavMenu;
