import React, {useMemo, useContext} from 'react';
import FadeIn from "react-fade-in";
import './BannerHome.css';
import {Button, Accordion, AccordionContext, Card, CardGroup, useAccordionToggle} from "react-bootstrap";
import {useProgressiveImage, useProgressiveImg} from "./useProgressiveImage";
import VideoEmbed from "./VideoEmbed";
import Signup from "./Signup";
import $ from "jquery";
import {useInView} from "react-intersection-observer";
import {useHistory} from "react-router-dom";

const BannerHomeSplit = (props) => {
    const expand = false;
    const [loaded, setLoaded] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [src1, { blur1 }] = useProgressiveImg('/images/meet2.jpg', '/images/meet2_small.jpg'); //width 300px
    const src = useProgressiveImage({ src:'/images/meet2.jpg', fallbackSrc: '/images/meet2_small.jpg'});
    const { ref, inView } = useInView({/* Optional options */ threshold: 0, });
    const history = useHistory();

    React.useEffect(() => {
    }, []);

    const startGrow = (loaded) => {
        setTimeout(function(){
            setLoaded(true);
        }, 550);
    };

    const goTo = (link) => {
        history.push(link);
    }

    function CustomToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext);
        const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
        const isCurrentEventKey = currentEventKey != eventKey;
        setExpanded(isCurrentEventKey ? false : true);
        return <Button className="button-margin-bottom" variant="outline-light" onClick={decoratedOnClick} >
                {isCurrentEventKey ? children : 'READ LESS'}
            </Button>;
    }

    return (
        <div className="meet-container">
            {/*minHeight: expanded ? '1100px' : '670px'}}>*/}
            <CardGroup className="section-video-pad">
                <a name="home"></a>
                    <Card className="no-room section meet-pic">
                        <div>
                            <div className="img-cont">
                                <FadeIn transitionDuration="600"  delay={0}>
                                    <div className="center-content" style={{width: '100%'}}>
                                        <div className="cropped">
                                            <img
                                                className={loaded ? "ban-zoom ban-zoom-active" : ""} onLoad={() => startGrow(true)}
                                                src={src}
                                                // style={{filter: blur1 ? "blur(20px)" : "none", transition: blur1 ? "none" : "filter 0.3s ease-out"}}
                                            />
                                        </div>
                                    </div>
                                </FadeIn>
                            </div>
                        </div>
                        <div className="home-text centered">
                            <Signup />
                        </div>
                    </Card>
                    <Card className="no-room section meet">
                        <div className="home-text-split centered-hori top-section">
                            <div>
                                <h1>
                                    <div className="animation">
                                        <span className="first">Meet</span>
                                        <span className="space"> </span>
                                        <span className="oh">
                                            <span className="second">Sean</span>
                                        </span>
                                    </div>
                                </h1>
                            </div>
                            <FadeIn transitionDuration="4000" delay="600">
                                <p>
                                    Get to know Sean Carroll, the former international pro track athlete turned professional bodybuilder.
                                    He has dedicated most of his life to sport showing a passion and drive to succeed and now he wants to help others succeed in their fitness/ aesthetic endeavours. </p>
                            </FadeIn>
                            <FadeIn transitionDuration="500" delay="2000">
                                {expand ? <Accordion defaultActiveKey="0">
                                    <CustomToggle eventKey="1">READ MORE</CustomToggle>
                                    <Accordion.Collapse className="expand-in-place" eventKey="1">
                                        <div className="meet-collapse-text">
                                          <p>Sean was always an active child growing up.
                                              He was a slight child who was heavily involved in sports generally.
                                              His love of sport initially came from track and field where he went onto represent the UK GB athletics team at junior level.</p>
                                          <p>After many successful years of competing as an athlete, injury struck and forced him to leave the sport he loved so much growing up as a child.
                                              This prompted him to going into study where he would pick up his BSc (hons) Sports Science degree, UK Strength and Conditioning and diet and nutrition certifications.
                                              While doing all of this, he managed to gain experience in Professional Rugby League, Rugby Union, Soccer and amateur boxing (ABA novice finalist).</p>
                                            <p>He became a IFBB Professional athlete in 2015 but his card was rescinded due to a visa technicality.
                                                This was unfortunate but that didn’t stop him. He later went onto win his Pro Card a second time in 2018 in Canada.
                                                Since achieving his IFBB Pro status he has gone onto helping and transforming hundreds of people/ client’s physiques and lives.</p>
                                            <p>Are you ready to make a change today? Are you ready work towards your fitness/ aesthetic goals?
                                                Look no further, you are in the right place! He will genuinely care for you and your overall health.
                                                He wants you to be the best possible version of you.
                                                His aim is to improve your confidence, increase your strength and fitness, achieve the shape that you’ve always wanted and help you gain that fit and toned look you’ve always dreamt about.  </p>
                                        </div>
                                    </Accordion.Collapse>
                                </Accordion> :
                                    <Button
                                        className="button-margin-bottom"
                                        variant="outline-light"
                                        onClick={() => goTo("/meetSean")} >
                                    READ MORE
                                </Button>}
                            </FadeIn>
                            <div className="centered-hori2 button-margin-bottom">
                                <FadeIn transitionDuration="2000" delay="800">
                                    <VideoEmbed videoUrl="https://drive.google.com/uc?export=download&id=124skFyGoVpVsiD5rJcvCpay0kXdciqbu" />
                                </FadeIn>
                            </div>
                        </div>
                    </Card></CardGroup>
            {/*<div className="center-content barrier" style={{width: '100%', height: '0px'}}>*/}
            {/*    <img className="center-me centered-hori" height="200px" src="/images/logo.png"/>*/}
            {/*</div>*/}
        </div>
    );
};
export default BannerHomeSplit;
