import React, {useMemo} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FadeIn from "react-fade-in";
import {Form} from "react-bootstrap";
import './Contact.css';
import {useInView} from "react-intersection-observer";
import {getBaseUrl, getHeaders} from "./Api";
import ModalLoad from "./ModalLoad";
import {IconButton} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const Contact = React.forwardRef((props, menuRef) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [fullNameRegex, setFullNameRegex] = React.useState("^[a-zA-Z0-9.\\-\\/+=@_' ]*$");
    const [messageRegex, setMessageNameRegex] = React.useState("^[\\s\\S]*?");
    const [numberRegex, setNumberRegex] = React.useState(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
    //const [emailRegex, setEmailRegex] = React.useState(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/);
    const [emailRegex, setEmailRegex] = React.useState(/^[\w\.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/);
    const [fullNameErrorText, setFullNameErrorText] = React.useState("");
    const [numberErrorText, setNumberErrorText] = React.useState("");
    const [emailErrorText, setEmailErrorText] = React.useState("");
    const [subjectErrorText, setSubjectErrorText] = React.useState("");
    const [messageErrorText, setMessageErrorText] = React.useState("");
    const [resultText, setResultText] = React.useState("");
    const [fullName, setFullName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [number, setNumber] = React.useState("");
    const [subject, setSubject] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [error, setError] = React.useState(false);
    const { ref, inView, entry } = useInView({/* Optional options */ threshold: 0, });
    const title = "CONTACT SEAN";
    const subTitle = "For any queries please get in touch:";
    React.useEffect(() => {
    }, [error, resultText]);

    const submit = () => {
        if(disableSubmit()) {
            return;
        }
        setIsLoading(true);
        const contactInfo = {fullName: fullName, email: email, number: number, subject: subject, message: message};
        fetch(getBaseUrl() + props.contactUrl, {
            method: 'POST',
            body: JSON.stringify({...contactInfo}),
            headers: getHeaders(),
        }).then(response =>
        {
            setIsLoading(false);
            if(response.status == 200 || response.status == 201){
                setError(false);
                setResultText("Thanks for getting in touch!");
            } else {
                setError(true);
                setResultText("Oops something went wrong...");
            }
        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
            setError(true);
            setResultText("Oops something went wrong...");
        });
    };

    const disableSubmit = () => {
        if(emailErrorText != "" || numberErrorText != "" || fullNameErrorText != "" || subjectErrorText != "" || messageErrorText != "" ||
            fullName == "" || number == "" || email == "" || subject == "" || message == ""){
            return true;}
        return false;
    };

    const renderLoading = () => { return isLoading ? <ModalLoad open={true} text="Sending your message"/> :[];};
    const contactForm = () => <div>
            <FadeIn transitionDuration="200" delay="200"><div style={{paddingLeft: '2vw', paddingRight: '2vw' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField required id="fullName" name="fullName" label="Full name" hintText="Full name" fullWidth autoComplete="given-name"
                                   error ={fullNameErrorText.length != 0}
                                   helperText={fullNameErrorText}
                                   value={fullName} onChange={(e) => { onChange(e, "fullName"); }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField required type="email" id="email" name="email" label="Email" hintText="Email address" fullWidth autoComplete="given-name"
                                   error ={emailErrorText.length != 0}
                                   helperText={emailErrorText}
                                   value={email} onChange={(e) => { onChange(e, "email"); }}
                        />
                        <Form.Text className="light-blue force-white">
                            We'll never share your email with anyone else.
                        </Form.Text>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="number" name="number" label="Phone number" hintText="Phone number" fullWidth autoComplete="phone number"
                                   error ={numberErrorText.length != 0}
                                   helperText={numberErrorText}
                                   value={number} onChange={(e) => { onChange(e, "number"); }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="subject" name="subject" label="Subject" hintText="Subject" fullWidth autoComplete="subject"
                                   error ={subjectErrorText.length != 0}
                                   helperText={subjectErrorText}
                                   value={subject} onChange={(e) => { onChange(e, "subject"); }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="message" name="message" label="Message" multiline={true} hintText="Message" fullWidth autoComplete="message"
                                   error ={messageErrorText.length != 0}
                                   helperText={messageErrorText}
                                   value={message} onChange={(e) => { onChange(e, "message"); }}
                        />
                    </Grid>
                </Grid></div></FadeIn>

            <FadeIn transitionDuration="400" delay="1200">
                <div style={{marginTop: '3vh', textAlign: 'center'}} className={disableSubmit() ? "css-button disabl" : "css-button"} onClick={() => submit()}>
                    <p className="css-button-text text-center">Send</p>
                    <div className="css-button-inner">
                        <div className="reset-skew">
                            <p className="css-button-inner-text">Send</p>
                        </div>
                    </div>
                </div></FadeIn>
            <Form.Text className="light-blue" style={{color: 'white', textAlign: 'center', visibility: `${disableSubmit() ? 'visible' : 'hidden'}`}}>
                Some fields have not been filled correctly.
            </Form.Text>
    </div>;

    const submitResult = () => <FadeIn transitionDuration="1000">
        <h2 className="formsubheader">{resultText}</h2>
        {error && !isLoading ? <div class="force-white" style={{color: 'white'}}>Please get in contact at&nbsp;
            <i><a className="required-field" href="mailto:scfit1@outlook.com">scfit1@outlook.com</a></i>
        </div> : !isLoading ? <div style={{ color: 'white', textAlign: 'center'}} >I will get back to you as soon as possible.</div> : []}
    </FadeIn>;

    const onChange = (event, field) => {
        switch (field) {
            case "fullName":
                setFullName(event.target.value);
                if(event.target.value.match(fullNameRegex)) {
                    setFullNameErrorText("");
                } else {
                    setFullNameErrorText("Please enter a valid name")
                }
                break;
            case "email":
                setEmail(event.target.value);
                if(event.target.value.match(emailRegex)) {
                    setEmailErrorText("");
                } else {
                    setEmailErrorText("Please enter a valid email address")
                }
                break;
            case "number":
                setNumber(event.target.value);
                if(event.target.value.match(numberRegex)) {
                    setNumberErrorText("");
                } else {
                    setNumberErrorText("Please enter a valid phone number")
                }
                break;
            case "subject":
                setSubject(event.target.value);
                if(event.target.value.match(fullNameRegex)) {
                    setSubjectErrorText("");
                } else {
                    setSubjectErrorText("Please enter a valid message subject")
                }
                break;
            case "message":
                setMessage(event.target.value);
                if(event.target.value.match(messageRegex)) {
                    setMessageErrorText("");
                } else {
                    setMessageErrorText("Please enter a valid message body")
                }
                break;
            default:
                return;
        }
    };

    return (<FadeIn transitionDuration="200" delay={0} visible={inView ? true : false}>
            <div ref={menuRef} className="center-content" style={{marginTop: '70px', justfyContent: 'center', minHeight: "600px"}}>
                {resultText == "" ? <div>
                    <FadeIn className="animation" transitionDuration="600" visible={inView ? true : false}><h1>{title}</h1></FadeIn>
                    <FadeIn className="formsubheader" transitionDuration="1400" visible={inView ? true : false}><h4>{subTitle}</h4></FadeIn>
                </div> : []}
                <div ref={ref} style={{color: 'white !important', marginTop: '30px'}}>
                    {resultText == "" ? contactForm() : submitResult()}
                </div>
                <div style={{marginTop: "1vh", visibility:  `${resultText == "" ? 'hidden' : 'visible'}`}}>
                    <FadeIn className="center-content" transitionDuration="400" visible={inView ? true : false}><IconButton
                        aria-label="check"
                        className="icon-button"
                        onClick={() => setResultText("")}>
                        <ArrowBackIcon style={{color: 'white'}}/>
                    </IconButton></FadeIn></div>
            </div>
            {renderLoading()}
        </FadeIn>);
});
export default Contact;

Contact.defaultProps = {
    contactUrl: 'contact'
};