const env = "prod";

export const getApi = () => {return "a9b3f05f-59d9-4668-addf-f5a943cad0b2"};

export function getBaseUrl() {
    return env == 'prod' ? "https://scfit1.com:443/api/" : "http://localhost:8091/api/";
};

export const getHeaders = () => {
    return env == 'prod' ? {'Content-Type': 'application/json'} : {"Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json'};
};