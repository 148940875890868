import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import './BannerHome.css';
import Section from "./Section";
import Contact from "./Contact";

const ContactStandAlone = () => {
    return (<Section high="500px"><Contact /></Section>);
};
export default ContactStandAlone;
