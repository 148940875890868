import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane, faSmileBeam, faFrown, faChevronCircleLeft} from "@fortawesome/free-solid-svg-icons";
import 'font-awesome/css/font-awesome.min.css';
import './BannerHome.css';
import './Section.css';
import {Form, Card, Container, Button, Spinner, Accordion, CardGroup} from 'react-bootstrap';
import FadeIn from "react-fade-in";
import {getBaseUrl, getHeaders} from "./Api"
import { useForm } from 'react-hook-form';
import { ScrollTo } from "react-scroll-to";
import ModalLoad from "./ModalLoad";

const Section = ({children, title, high, pad}) => {
    const [email, setEmail] = React.useState("");
    const [name, setName] = React.useState("");
    const [text, setText] = React.useState("");
    const [responseStatus, setResponseStatus] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const { register, handleSubmit, errors } = useForm();

    React.useEffect(() => {
    }, [])

    return (<div style={{minWidth: '100%', marginTop: '150px'}}>
        <ScrollTo>{({ scroll }) => scroll({ x: 0, y: 0, smooth: true })}</ScrollTo>
        <Card className="no-room meet" style={{ minHeight: high}}>
            <div className="home-text-split centered" style={{ paddingTop: pad == undefined ? "0px" : pad}}>
                <div className="animation">
                    <FadeIn transitionDuration="1000" delay="0"><h1>{title}</h1></FadeIn>
                </div>
                <FadeIn transitionDuration="1000" delay="200">
                    {children}
                </FadeIn>
            </div>
        </Card></div>);
};
export default Section;

Section.defaultProps = {
    emailUrl: 'email'
};

