import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import './BannerHome.css';
import Section from "./Section";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const Coaching = () => {
    const title = "Coaching";

    React.useEffect(() => {
    }, [])

    const sectionBody = () => <div>
        <p>What’s included?</p>
        <ul>
            <li>A tailor-made training plan suited to your needs that works in line with a diet and nutrition plan to get you to achieve your fitness, aesthetic and sports specific goals.</li>
            <li>Plans will be modified to your needs, likes and dislikes while utilizing your current and previous nutritional habits.</li>
            <li>Education will be given where necessary so you understand why and what you’re doing to help you reach your goal.</li>
            <li>Varied exercise protocols used to help you reach your target goals.</li>
            <li>Instruction will be given to help initiate long lasting habits that fit in line with your lifestyle needs and everyday life.</li>
            <li>Bi-weekly check-ins for all clients to assess current progress with adjustments will be sent (watsapp and facetime calls when agreed upon)</li>
            <li>24h WhatsApp support – will respond ASAP upon receiving texts</li>
            <li>The aim is to get you into your best possible shape/ condition ever!</li>
        </ul>
    </div>;

    return (<Section high="500px" title={title}>{sectionBody()}</Section>);
};
export default Coaching;
