import React from 'react';
import Paper from '@material-ui/core/Paper';
import FadeIn from "react-fade-in";
import {useInView} from "react-intersection-observer";
import ImageCompare from "./ImageCompare";
import {Button, Card, CardGroup} from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import VideoEmbed from "./VideoEmbed";
import {useHistory} from "react-router-dom";
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import PersonalVideoIcon from '@material-ui/icons/PersonalVideo';
import SportsIcon from '@material-ui/icons/Sports';

const CoachingHome = (props) => {
    const { ref, inView } = useInView({/* Optional options */ threshold: 0, });
    const history = useHistory();

    React.useEffect(() => {
    }, []);

    const goTo = (link) => {
        history.push(link);
    };

    const videoCard = () => <Card className="no-room section meet h800">
        <a name="coaching"></a>
        <div className="home-text-split centered-hori">
            <FadeIn transitionDuration="1000" delay={0} visible={inView ? true : false} className="full-height">
                <div className="animation"><h1>Coaching</h1></div>
            </FadeIn>
            <FadeIn transitionDuration="1000" delay="200" visible={inView ? true : false}>
                <p>Here you will be provided with a training program which looks at improving your physique and diet and nutrition. Your training program will be tailor made to meet your specific goals.</p>
            </FadeIn>
            <FadeIn transitionDuration="1000" delay="400" visible={inView ? true : false} ><KeyboardArrowDownIcon/></FadeIn>
            <FadeIn transitionDuration="1000" delay="600" visible={inView ? true : false}>
                <Button
                    className="button-margin-bottom"
                    variant="outline-light"
                    onClick={() => goTo("/coaching")} >
                    READ MORE
                </Button>
            </FadeIn>
            <div className="centered-hori2 button-margin-bottom">
                <FadeIn transitionDuration="2000" delay="800">
                    <VideoEmbed videoUrl="https://drive.google.com/uc?export=download&id=12CfZY83862YdubsrK0JKPI29BMFUeU9B" />
                </FadeIn>
            </div>
        </div>
    </Card>;

    const imgCard = () => <Card className="no-room section">
        <div style={{backgroundImage: 'url("/images/coaching2.jpg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center', height: '100%'}}>
            <FadeIn transitionDuration="1000" delay={0} visible={inView ? true : false} className="full-height">
                <div className="center-content container-body">
                    <div className="animation">
                        <EmojiEventsIcon fontSize="large"/>
                        <h3 style={{marginTop: "5px"}}>Competition Prep</h3><FadeIn transitionDuration="1000" delay="600"
                                                                                    visible={inView ? true : false}><Button className="button-margin-bottom" variant="outline-light"
                                                                                                                            onClick={() => goTo("/compprep")}>READ MORE</Button></FadeIn>
                    </div>
                    <div className="animation">
                        <FastfoodIcon fontSize="large"/>
                        <h3 style={{marginTop: "5px"}}>Lifestyle Prep</h3><FadeIn transitionDuration="1000" delay="600"
                                                                                  visible={inView ? true : false}><Button className="button-margin-bottom" variant="outline-light"
                                                                                                                          onClick={() => goTo("/lifeprep")}>READ MORE</Button></FadeIn>
                    </div>
                    {/*<div className="animation"><h3>- Strength and conditioning -</h3><FadeIn transitionDuration="1000" delay="600"*/}
                    {/*                                                                         visible={inView ? true : false}><Button className="button-margin-bottom" variant="outline-light"*/}
                    {/*                                                                                                                 onClick={() => goTo("/strength")}>READ MORE</Button></FadeIn>*/}
                    {/*</div>*/}
                    {/*<div className="animation"><h3>- Glute and leg development -</h3><FadeIn transitionDuration="1000" delay="600"*/}
                    {/*                                                                         visible={inView ? true : false}><Button className="button-margin-bottom" variant="outline-light"*/}
                    {/*                                                                                                                 onClick={() => goTo("/glute")}>READ MORE</Button></FadeIn>*/}
                    {/*</div>*/}

                    <div className="animation">
                        <PersonalVideoIcon fontSize="large" />
                        <h3 style={{marginTop: "5px"}}>Virtual PT</h3><FadeIn transitionDuration="1000" delay="600"
                                                                              visible={inView ? true : false}><Button className="button-margin-bottom" variant="outline-light"
                                                                                                                      onClick={() => goTo("/virtual")}>READ MORE</Button></FadeIn>
                    </div>
                    <div className="animation">
                        <SportsIcon fontSize="large" />
                        <h3 style={{marginTop: "5px"}}>PT (London localised area)</h3><FadeIn transitionDuration="1000" delay="600"
                                                                                              visible={inView ? true : false}><Button className="button-margin-bottom" variant="outline-light"
                                                                                                                                      onClick={() => goTo("/pt")}>READ MORE</Button></FadeIn>
                    </div>
                    {/*<div className="animation"><h3>- Celebrity -</h3><FadeIn transitionDuration="1000" delay="600"*/}
                    {/*                                                         visible={inView ? true : false}><Button className="button-margin-bottom" variant="outline-light"*/}
                    {/*                                                                                                 onClick={() => goTo("/celebrity")}>READ MORE</Button></FadeIn>*/}
                    {/*</div>*/}
                </div>
            </FadeIn>
        </div>
    </Card>;

    return (
        <div style={{minWidth: '100%'}} ref={ref}>
            <CardGroup>
                {window.innerWidth < 1000 ? videoCard() : imgCard()}
                {window.innerWidth < 1000 ? imgCard() : videoCard()}
            </CardGroup>
        </div>
    );
};
export default CoachingHome;
