import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faSearch,
    faSmileBeam,
    faFrown,
    faChevronCircleLeft,
    faHashtag,
    faCommentAlt
} from "@fortawesome/free-solid-svg-icons";
import 'font-awesome/css/font-awesome.min.css';
import {Form, CardColumns, Card, Container, ListGroup, ListGroupItem, Button, Spinner} from 'react-bootstrap';
import FadeIn from "react-fade-in";
import {getBaseUrl, getHeaders} from "./Api";
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {faShippingFast, faHandHoldingUsd, faPeopleCarry, faBoxOpen} from "@fortawesome/free-solid-svg-icons";
import ModalLoad from "./ModalLoad";

const useStyles = makeStyles((theme) => ({
    secondaryTail: {
        backgroundColor: "indianred",
    },
}));

const OrderStatus = (props) => {
    const [email, setEmail] = React.useState("");
    const [orderId, setOrderId] = React.useState();
    const [date, setDate] = React.useState("");
    const [total, setTotal] = React.useState("");
    const [productOrder, setProductOrder] = React.useState(null);
    const [dateTransaction, setDateTransaction] = React.useState("");
    const [responseStatus, setResponseStatus] = React.useState(null);
    const [status, setStatus] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [lod, setLod] = React.useState(false);
    const classes = useStyles();

    React.useEffect(() => {
    }, [responseStatus])

    const goToContact = () => {
        setResponseStatus(null);
    }

    const submit = () => {
        setIsLoading(true);
        fetch(getBaseUrl() + props.orderStatusUrl + new URLSearchParams({ id: orderId.toString(), email: email}), {
            method: 'GET',
            headers: getHeaders(),
        }).then(response => {const json = response.json(); setResponseStatus(response.status); return json;}).then(data => {
            //setResponseStatus(response.status);
            setIsLoading(false);
            if(data.dateCreated != undefined && data.dateCreated != "") {
                setDate(data.dateCreated);
                setDateTransaction(data.transactionDateTime);
                setStatus(data.status);
                setTotal(data.cv2);
                setProductOrder(data.orderProducts);
            }
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
        });
    };

    const renderLoading = () => {
        return (isLoading && window.innerWidth >= 690) ?
            <div className='clearfix text-center'>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div> : (isLoading && window.innerWidth < 690) ? <ModalLoad open={true}/>:[];
    };

    const info = () => {
        return <div className="center-me">
            {(responseStatus == 200) ?
                <FadeIn><Card.Title>
                    Order <b style={{color: 'indianred'}}>#{orderId}</b></Card.Title>
                    <Card.Text>Order Date: <i style={{marginLeft: '5px'}}>{date}</i></Card.Text>
                    <Card.Text>Order Total: <i style={{marginLeft: '5px'}}>{total}</i></Card.Text>
                    <FadeIn delay={500}>
                        <Button style={{marginTop: '10px'}}
                                onClick={() => goToContact()}
                                variant="outline-dark">BACK TO SEARCH <FontAwesomeIcon icon={faChevronCircleLeft}/></Button></FadeIn>
            </FadeIn> : []}
        </div>;
    };

    const products = () => {
        return <div className="center-me"><FadeIn>
                    <ListGroup className="list-group-flush">
                        {productOrder != null ? productOrder.map((order, i) =>
                            <ListGroupItem>{order.quantity} x {order.product.name} - {order.product.size}</ListGroupItem>):[]}
                    </ListGroup>
                </FadeIn></div>
    };

    const sorry = () => {
        return <div className="center-me">
            {(responseStatus != null && responseStatus != 200) ?
                <><FadeIn><Card.Title>We can't seem to find your order <FontAwesomeIcon icon={faFrown} className="second-col"/>
                </Card.Title><Card.Subtitle>Make sure you entered the correct details and try again.</Card.Subtitle>
                    <Card.Text>Alternatively get in touch at <i><a className="required-field" href="mailto:sean.ldn@gmail.com">sean.ldn@gmail.com</a></i></Card.Text></FadeIn>
                    <FadeIn delay={500}>
                        <Button style={{marginTop: '30px'}}
                                onClick={() => goToContact()}
                                variant="outline-dark">BACK TO SEARCH <FontAwesomeIcon icon={faChevronCircleLeft}/></Button></FadeIn></> : []}
        </div>;
    };

    const formFields = () => {
        return <>
            {(responseStatus == null) ?
                <Form className="contact-form">
                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>E-Mail <b className="required-field">*</b></Form.Label>
                        <Form.Control required type="email" placeholder="name@example.com" value={email} onChange={(e) => { setEmail(e.target.value); }}/>
                        <Form.Text className="text-muted">The email address you used to complete the order.</Form.Text>
                        <Form.Control.Feedback type="invalid">Please provide the email you used to complete the order.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group >
                        <Form.Label>Order ID <b className="required-field">*</b></Form.Label>
                        <Form.Control required placeholder="1234" value={orderId} onChange={(e) => { setOrderId(e.target.value); }}/>
                        <Form.Text className="text-muted">The order ID/number you received for your order.</Form.Text>
                        <Form.Control.Feedback type="invalid">Please provide the order ID you received for your order.</Form.Control.Feedback>
                    </Form.Group>
                    <FadeIn><Button disabled={isLoading ? "disabled" : ""} onClick={() => submit()}
                                    variant="outline-dark">FIND ORDER <FontAwesomeIcon icon={faSearch}/></Button></FadeIn>
                </Form> : <div></div>} </>;
    };

    function getStatus(){
        return <Timeline align="left" className="no-pads">
            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography variant="body2" color="textPrimary" className={status.includes(".PAID") ? '' : 'greyout'}>
                        <small>{status.includes(".PAID") ? status.split(".PAID|")[1].split(".")[0] : ""}</small>
                    </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot style={{color: 'white', backgroundColor: `${status.includes(".PAID") ? 'indianred' : ''}`}}>
                        <FontAwesomeIcon icon={faHandHoldingUsd} className={status.includes(".PAID") ? '' : 'greyout'}/>
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3} className={status.includes(".PAID") ? 'order-paper' : 'order-paper greyout'}>
                        <Typography variant="h6" component="h1">
                            Received
                        </Typography>
                        <Typography>We have got your order</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography variant="body2" color="textPrimary" className={status.includes("SHIPPED") ? '' : 'greyout'}>
                        <small>{status.includes("SHIPPED") ? status.split(".SHIPPED|")[1].split(".")[0] : ""}</small>
                    </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot style={{color: 'white', backgroundColor: `${status.includes("SHIPPED") ? 'black' : ''}`}}>
                        <FontAwesomeIcon icon={faPeopleCarry} className={status.includes("SHIPPED") ? '' : 'greyout'}/>
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3} className={status.includes("SHIPPED") ? 'order-paper' : 'order-paper greyout'}>
                        <Typography variant="h6" component="h1">
                            Dispatched
                        </Typography>
                        <Typography>Order has been processed</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography variant="body2" color="textPrimary" className={status.includes("TRANSIT") ? '' : 'greyout'}>
                        <small>{status.includes("TRANSIT") ? status.split(".TRANSIT|")[1].split(".")[0] : ""}</small>
                    </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot variant="outlined" style={{borderColor: `${status.includes("TRANSIT") ? 'indianred' : ''}`}}>
                        <FontAwesomeIcon icon={faShippingFast} className={status.includes("TRANSIT") ? '' : 'greyout'}/>
                    </TimelineDot>
                    <TimelineConnector
                        // className={classes.secondaryTail}
                    />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3} className={status.includes("TRANSIT") ? 'order-paper' : 'order-paper greyout'}>
                        <Typography variant="h6" component="h1">
                            Out for Delivery
                        </Typography>
                        <Typography>Order will be with you shortly</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent>
                    <Typography variant="body2" color="textPrimary" className={status.includes("DELIVERED") ? '' : 'greyout'}>
                        <small>{status.includes("DELIVERED") ? status.split(".DELIVERED|")[1].split(".")[0] : ""}</small>
                    </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot style={{borderColor: `${status.includes("DELIVERED") ? 'black' : ''}`, backgroundColor: `${status.includes("DELIVERED") ? 'indianred' : ''}`}}>
                        <FontAwesomeIcon icon={faBoxOpen} className={status.includes("DELIVERED") ? '' : 'greyout'}/>
                    </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3} className={status.includes("DELIVERED") ? 'order-paper' : 'order-paper greyout'}>
                        <Typography variant="h6" component="h1">
                            Delivered
                        </Typography>
                        <Typography>Enjoy!</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        </Timeline> };

    return (<div style={{width: '100%', minHeight: '65vh'}}>
        <CardColumns className={(responseStatus == 200) ? "": "colz1"}>
            <Card className={(responseStatus == 200) ? "card-min-with": "card-min-with"}>
                <Card.Header>Order Info</Card.Header>
                <Card.Body><Container>
                    {sorry()}
                    {info()}
                    {renderLoading()}
                    {formFields()}
                </Container></Card.Body>
            </Card>{(responseStatus == 200) ? <>
            <Card className="card-min-with no-pads"><Card.Header>Order Status</Card.Header><Card.Body className="order-timeline no-pads">{getStatus()}</Card.Body></Card>
            <Card className="card-min-with no-pads"><Card.Header>Order Products</Card.Header><Card.Body>{products()}</Card.Body></Card></> : []}
        </CardColumns></div>);
};
export default OrderStatus;

OrderStatus.defaultProps = {
    orderStatusUrl: 'orderstatus?'
};

