import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import './BannerHome.css';
import Section from "./Section";

const Meet = () => {
    const title = "Meet Sean";

    React.useEffect(() => {
    }, [])

    const sectionBody = () => <div><p>
        Get to know Sean Carroll, the former international pro track athlete turned professional bodybuilder.
        He has dedicated most of his life to sport showing a passion and drive to succeed and now he wants to help you succeed in their fitness/ aesthetic endeavours. </p>
        <p>Sean was always an active child growing up.
            He was a slight child who was heavily involved in sports generally.
            His love of sport initially came from track and field where he went onto represent the UK GB athletics team at junior level.</p>
        <p>After many successful years of competing as an athlete, injury struck and forced him to leave the sport he loved so much growing up as a child.
            This prompted him to go into study where he would pick up his BSc (hons) Sports Science degree, UK Strength and Conditioning and diet and nutrition certifications.
            While doing all of this, he managed to gain experience in Professional Rugby League, Rugby Union, Soccer and amateur boxing (ABA novice finalist).</p>
        <p>He became a IFBB Professional athlete in 2015 but his card was rescinded due to a visa technicality.
            This was unfortunate but that didn’t stop him. He later went onto win his Pro Card a second time in 2018 in Canada.
            Since achieving his IFBB Pro status he has gone onto helping and transforming hundreds of people/ client’s physiques and lives.</p>
        <p>Are you ready to make a change today? Are you ready work towards your fitness/ aesthetic goals?
            Look no further, you are in the right place! He will genuinely care for you and your overall health.
            He wants you to be the best possible version of you.
            His aim is to improve your confidence, increase your strength and fitness, achieve the shape that you’ve always wanted and help you gain that fit and toned look you’ve always dreamt about.  </p>
    </div>;

    return (<Section high="900px" title={title}>{sectionBody()}</Section>);
};
export default Meet;
