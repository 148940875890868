import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import './BannerHome.css';
import Section from "./Section";
import FadeIn from "react-fade-in";
import {Button} from "react-bootstrap";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import {useHistory} from "react-router-dom";
const services = require('../assets/services.json');

const Posing = () => {
    const title = "Posing";
    const history = useHistory();
    const serviceId = "posing";
    const serviceInfo = services.find(service => service.id === serviceId);

    React.useEffect(() => {
    }, [])

    const goTo = (link) => {
        history.push(link);
    }

    const goToExternal = (link) => {
        window.open(link);
    }

    const sectionBody = () => <div>
        <FadeIn transitionDuration="100" delay="0">
        <p>
            ‘To assume a series of positions to be marked in any given bodybuilding competitive division by a judge’. Here you will learn how to pose in your category according to the criteria set by your federation.
        </p>
        </FadeIn>
        <FadeIn transitionDuration="1500" delay="500">
            <div>
                {/*                <span className="light-blue">Taster one off session <strong>£80 </strong><Button*/}
                {/*                    className="button-margin-bottom btn-stripe"*/}
                {/*                    onClick={() => goToExternal("https://buy.stripe.com/28o17waV33YJ6jebIR")} >*/}
                {/*                    <img height="20px" src="./images/StripeLogo.png" /> <CreditCardIcon />*/}
                {/*                </Button><div></div></span>*/}
                {/*<span className="light-blue">3 sessions minimum <strong>£80ph</strong><Button*/}
                {/*    className="button-margin-bottom btn-stripe"*/}
                {/*    onClick={() => goToExternal("https://buy.stripe.com/eVacQebZ7dzj22Y7sz")} >*/}
                {/*                    <img height="20px" src="./images/StripeLogo.png" /> <CreditCardIcon />*/}
                {/*                </Button><div></div></span>*/}
                {/*<span className="light-blue">6 sessions minimum <strong>£80ph</strong><Button*/}
                {/*    className="button-margin-bottom btn-stripe"*/}
                {/*    onClick={() => goToExternal("https://buy.stripe.com/aEUcQee7fcvf0YUeV2")} >*/}
                {/*                    <img height="20px" src="./images/StripeLogo.png" /> <CreditCardIcon />*/}
                {/*                </Button><div></div></span>*/}



                    {serviceInfo.duration.map(d =>
                        <div><span className="light-blue">{d} - <i><strong>{serviceInfo.price}</strong></i></span><br/></div>
                    )}
                    <Button className="button-margin-bottom btn-paypal no-marg-left"
                            onClick={() => goToExternal(serviceInfo.url)} >
                        <img height="20px" src="./images/PayPal.png" />
                    </Button><div><small><i>All payments taken through paypal</i></small></div><br/>
                <a href="#contact">
                    <Button
                    className="button-margin-bottom"
                    variant="outline-light">
                    ENQUIRE
                </Button></a>
            </div>
        </FadeIn>
    </div>;

    return (<Section high="500px" title={title}>{sectionBody()}</Section>);
};
export default Posing;
