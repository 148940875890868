import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import './BannerHome.css';
import Section from "./Section";
import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";
const services = require('../assets/services.json');

const LifePrep = () => {
    const title = "Life Prep";
    const history = useHistory();
    const serviceId = "online-life";
    const serviceInfo = services.find(service => service.id === serviceId);
    const serviceId2 = "diet-single";
    const serviceInfo2 = services.find(service => service.id === serviceId2);

    React.useEffect(() => {
    }, [])

    const goTo = (link) => {
        history.push(link);
    }

    const goToExternal = (link) => {
        window.open(link);
    }

    const sectionBody = () => <div>
        <p>What’s included?</p>
        <ul>
            <li>Lifestyle prep is for those who wish to maintain a lifestyle that accommodates a workout plan that will help them achieve their physique goals. This program is slightly more relaxed to that of a competition prep.</li>
            <li>A relaxed outlook to meals within reason will be utilized to accommodate your exercise plan. You still have to put the work in and in some cases specific foods may be restricted to help you reach your goals.</li>
            <li>Exercise, diet and nutrition plans are all focused around phases and check-in dates (periodization).</li>
            <li>You will adhere to a calendar stipulating these phases and designed changes. These are back tracked from your final target date.</li>
            <li>As long as you are able to stay in communication and follow the plans made for you then you will achieve and gain the best possible results for you.</li>
        </ul>
        {/*<span className="light-blue">16 weeks – 4 months (highly recommended)<div><i><strong>£600</strong></i></div></span>*/}
        {/*<span className="light-blue">14 weeks – 3.5 months (standard recommendation)<div><i><strong>£525</strong></i></div></span>*/}
        {/*<span className="light-blue">12 weeks – 3 months (minimum recommendation)<div><i><strong>£450</strong></i></div></span>*/}
        {serviceInfo.duration.map(d =>
            <div><span className="light-blue">{d} - <i><strong>{serviceInfo.price}</strong></i></span><br/></div>
        )}
        <br/>
        <strong>- {serviceInfo2.name} -</strong>
        {serviceInfo2.duration.map(d =>
            <div><span className="light-blue">{d} - <i><strong>{serviceInfo2.price}</strong></i></span><br/></div>
        )}
        <Button className="button-margin-bottom btn-paypal no-marg-left"
                onClick={() => goToExternal(serviceInfo.url)} >
            <img height="20px" src="./images/PayPal.png" />
        </Button><div><small><i>All payments taken through paypal</i></small></div><br/>
        <Button
            className="button-margin-bottom"
            variant="outline-light"
            onClick={() => goTo("/contact")} >
            ENQUIRE
        </Button>
    </div>;

    return (<Section high="500px" title={title}>{sectionBody()}</Section>);
};
export default LifePrep;
