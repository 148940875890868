import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import './BannerHome.css';
import Section from "./Section";
import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";
const services = require('../assets/services.json');

const Virtual = () => {
    const title = "Virtual PT";
    const history = useHistory();
    const serviceId = "online-pt";
    const serviceInfo = services.find(service => service.id === serviceId);

    React.useEffect(() => {
    }, [])

    const goTo = (link) => {
        history.push(link);
    }

    const goToExternal = (link) => {
        window.open(link);
    }

    const sectionBody = () => <div>
        <p>What’s included?</p>
        <p>Sessions are planned in advance with agreed equipment available to you.</p>
        <p>Sessions last for 1 hour</p>
        {/*<span className="light-blue">block sessions (minimum 6 upwards only)<div><i><strong>£60ph</strong></i></div></span>*/}
        {serviceInfo.duration.map(d =>
            <div><span className="light-blue">{d} - <i><strong>{serviceInfo.price}</strong></i></span><br/></div>
        )}
        <Button className="button-margin-bottom btn-paypal no-marg-left"
                onClick={() => goToExternal(serviceInfo.url)} >
            <img height="20px" src="./images/PayPal.png" />
        </Button><div><small><i>All payments taken through paypal</i></small></div><br/>
        <Button
            className="button-margin-bottom"
            variant="outline-light"
            onClick={() => goTo("/contact")} >
            ENQUIRE
        </Button>
    </div>;

    return (<Section high="500px" title={title}>{sectionBody()}</Section>);
};
export default Virtual;
