//NOT IN USE -not using takepayments
import React from "react";
import checkBrowser from "./checkBrowser";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {transactionActionCreators} from "../store/Transaction";
import {Spinner, Button, Breadcrumb, Card} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFrown, faLock } from "@fortawesome/free-solid-svg-icons";
import FadeIn from "react-fade-in";
import {useHistory} from "react-router-dom";
import * as QueryString from "query-string"
import {getBaseUrl, getHeaders} from "./Api";

const TransactionResult = (props) => {
    const [transaction, setTransaction] = React.useState({});
    const [canRequestResults, setCanRequestResults] = React.useState(false);
    const [errorOccured, setErrorOccured] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [hashDigest, setHashDigest] = React.useState();
    const [crossReference, setCrossReference] = React.useState(null);
    const [merchantID, setMerchantID] = React.useState();
    const [orderID, setOrderID] = React.useState();
    const history = useHistory();

    React.useEffect(() => {
        if(canRequestResults && props.transaction.length == 0) {
            props.requestTransaction(props.transactionInfoUrl, merchantID, "", crossReference);
        }
        else if(canRequestResults && transaction.length == 0) {
            setTransaction(props.transaction);
        }
        else if (!canRequestResults && crossReference === null) {
            let UrlQueryStrings = history.location.search;
            console.log(UrlQueryStrings);
            if(QueryString != undefined) {
                const queryValues = QueryString.parse(UrlQueryStrings);
                setHashDigest(queryValues.HashDigest);
                setMerchantID(queryValues.MerchantID);
                setOrderID(queryValues.OrderID);
                setCrossReference(queryValues.CrossReference);
            }
        }
        else if (!canRequestResults && crossReference !== null) {
            verifyTransaction();
        }
    }, [crossReference, canRequestResults]);

    const verifyTransaction = () => {
        setIsLoading(true);
        fetch(getBaseUrl() + props.transactionUrl, {
            method: 'POST',
            body: JSON.stringify({hashDigest: hashDigest, merchantID: merchantID, orderID: orderID, crossReference: crossReference}),
            headers: getHeaders(),
        }).then(response => response.json()).then(data => {
            setIsLoading(false);
            data.success == "SuccessMessage" ? setCanRequestResults(true) : setErrorOccured(true);
            console.log(data.message);
        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
        });
    };

    const renderLoading = () => {
        return <div className='clearfix text-center' style={{marginTop: '30vh'}}>
            {(isLoading) ? <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner> : []}
        </div>;
    };

    const goToCheckout = () => {
        history.push("/basket");
    }

    return (<div style={{width: '100%', minHeight: '70vh'}}>
        {renderLoading()}
        {errorOccured ? <FadeIn><Card style={{minHeight: '70vh', textAlign: 'center'}}>
            <Card.Title>An error occurred while processing the payment <FontAwesomeIcon icon={faFrown} className="second-col"/></Card.Title>
            <Card.Subtitle>Please try again, or alternatively get in touch at <i><a className="required-field" href="mailto:sean.ldn@gmail.com">sean.ldn@gmail.com</a></i></Card.Subtitle>
            <FadeIn delay="500"><Button disabled={isLoading ? "disabled" : ""} style={{width: '300px', marginTop: '5vh'}}
                                        className="center-content" variant="outline-dark" onClick={() => goToCheckout()}>
                <FontAwesomeIcon style={{marginRight: '8px'}}icon={faLock}/> CHECKOUT SECURELY</Button></FadeIn>
        </Card></FadeIn> : []}
        {transaction.length != 0 ? <FadeIn></FadeIn> : []}
    </div>);
};

export default connect(
    state => state.transaction,
    dispatch => bindActionCreators(transactionActionCreators, dispatch)
)(TransactionResult);

TransactionResult.defaultProps = {
    transactionUrl: "transaction",
    transactionInfoUrl: "transactioninfo",
    // transactionInfoUrl: "https://mms.tponlinepayments2.com/Pages/PublicPages/PaymentFormResultHandler.ashx" //"Content-Type", "application/x-www-form-urlencoded"
};
