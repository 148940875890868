import React from 'react';
import FadeIn from "react-fade-in";
import {useInView} from "react-intersection-observer";
import {Collapse, Button} from "react-bootstrap";
import {faInstagram} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//https://embedsocial.com/free-instagram-widget/
const InstaHome = (props) => {
    const { ref, inView } = useInView({/* Optional options */ threshold: 0, });
    const [instaLoaded, setInstaLoaded] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if(!instaLoaded) {
            embedSocial(document, "script", "EmbedSocialHashtagScript");
        }
    }, [instaLoaded, open]);

    const embedSocial = (d, s, id) => {
        let js;
        if (d.getElementById(id)) {return;}
        js = d.createElement(s);
        js.id = id;
        js.src = "https://embedsocial.com/cdn/ht.js";
        d.getElementsByTagName("head")[0].appendChild(js);
        setInstaLoaded(true);

    };

    return (<div ref={ref}>
        <FadeIn transitionDuration="200" delay={0} visible={inView ? true : false}>
            {props.collapse ?
                <>
                    <div className="center-content">
                        <Button
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                            className="button-margin-bottom prev space-on-top-more"
                            variant="outline-light"
                            onClick={() => setOpen(!open)} >
                            {open ? 'HIDE FEED' : 'SHOW FEED'} <FontAwesomeIcon icon={faInstagram}/>
                        </Button>
                    </div>
                    <Collapse in={open} timeout="1000">
                        <div id="example-collapse-text">
                            <div
                                className="embedsocial-hashtag"
                                data-ref="77670b2b6f060232f27488fc2646445387cc018d"></div>
                        </div>
                    </Collapse></> :
                <div
                    className="embedsocial-hashtag"
                    data-ref="77670b2b6f060232f27488fc2646445387cc018d"></div>
            }
        </FadeIn>
    </div>
    );
};
export default InstaHome;
