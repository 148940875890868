import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import './BannerHome.css';
import Section from "./Section";

const Terms = () => {
    const title = "Terms & Conditions";

    React.useEffect(() => {
    }, [])

    const sectionBody = () => <div>
        <p>Terms for online coaching</p>
        <ul>
            <li>No refunds.</li>
            <li>Pay monthly.</li>
         </ul>
    </div>;

    return (<Section high="500px" title={title}>{sectionBody()}</Section>);
};
export default Terms;
