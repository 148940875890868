import React from 'react';
import { connect } from 'react-redux';
import HomeCarousel from './HomeCarousel';
import CoachingHome from './CoachingHome';
import {Row, Col} from 'react-bootstrap';
import anime from 'animejs';
import './Home.css';
import ContactHome from "./ContactHome";
import PosingHome from "./PosingHome";
import TestimonialHome from "./TestimonialHome";
import TransformationHomeSplit from "./TransformationHomeSplit";
import StartHome from "./StartHome";
import InstaHome from "./InstaHome";
import BannerHomeSplit from "./BannerHomeSplit";
import AppHome from "./AppHome";

//https://developers.google.com/docs/api/samples/extract-text
//https://stackoverflow.com/questions/59923496/how-can-i-show-the-content-of-a-text-file-with-google-drive-api-v3-and-php
const Home = props => {
   React.useEffect(() => {
   }, [])

    return (<div style={{minWidth: '100%'}}>
        <BannerHomeSplit />
        <TransformationHomeSplit />
        <CoachingHome />
        <PosingHome />
        <ContactHome />
        {/*<StartHome />*/}
        {/*<InstaHome />*/}
        {/*<AppHome />*/}
    </div>);
};

export default connect()(Home);
