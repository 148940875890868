import React from "react";
import ReactPlayer from 'react-player';
import YouTube from 'react-youtube';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import {Container, Row, Col } from 'react-bootstrap';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Divider from '@material-ui/core/Divider';

const VideoEmbed = (props) => {
    const [checked, setChecked] = React.useState(false);
    const [player, setPlayer] = React.useState(null);
    const [currentTime, setCurrentTime] = React.useState(0);
    const [ticker, setTicker] = React.useState(undefined);
    const [end, setEnd] = React.useState(false);
    const [started, setStarted] = React.useState(false);
    const [playerReady, setPlayerReady] = React.useState(false);
    const [link, setLink] = React.useState("https://www.youtube.com/watch?v=UaxUhf7tG30");
    const [link2, setLink2] = React.useState("https://drive.google.com/uc?export=download&id=1Hlg93JgxJVKecUHf7dHnl7ZipP_Av-Yc");

    React.useEffect(() => {
    }, [props.videoUrl], player)

    const _onEnd = () => { setEnd(true);};
    const _onPause = () => { };
    const _onPlay = () => { };
    const _onStart = () => { setStarted(true);}
    const _onReady = (player) => {
        // access to player in all event handlers via event.target
        setPlayer(player);
        //player.pauseVideo();
        setPlayerReady(true);
    };

    return(<div className="center-content youtube-vid">
                <Row className="force-center back-black">
                    <ReactPlayer
                        className='react-player'
                        url={props.videoUrl} //url={props.info.videoUrl}
                        playing={false}
                        loop={true}
                        controls={true}
                        light={false}
                        volume={null}
                        muted={false}
                        onReady={_onReady}
                        onStart={_onStart}
                        onPlay={(e) => _onPlay(e)}
                        // onDuration
                        // onProgress
                        onPause={_onPause}
                        onEnded={_onEnd}
                        playIcon={<PlayCircleOutlineIcon></PlayCircleOutlineIcon>}
                    />
            </Row>
    </div>);
};
export default VideoEmbed;