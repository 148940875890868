import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import './BannerHome.css';
import Section from "./Section";
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const Transformations = () => {
    const title = "How will you transform?";

    React.useEffect(() => {
    }, [])

    const sectionBody = () => <div><p>
        To transform is to change completely the appearance or character of something or someone, especially so that that thing or person is improved. The aim is always to improve. Consistency is key!
    </p>
        <p><i><FormatQuoteIcon/>Hope is not a strategy<FormatQuoteIcon/></i></p>
        <p>Throughout any of the packages chosen we will look at changing your:

            physical appearance to suit your needs
            strength and condition to improve core fitness foundations
            applied progressive training for sports specific related events
            approach to training, diet and nutrition

            There a number of packages that you may wish to use; however, time allocation to each package will need some thought:
        </p>
        <ul>
            <li>Off season and full competitive season – online (highly recommended) £200 pcm</li>
            <li>Competition – 16 weeks – 4 months (highly recommended) £800</li>
            <li>Competition – 14 weeks – 3.5 months (standard recommendation) £700</li>

            <li>Competition – 12 weeks – 3 months (minimum recommendation) £600</li>
            <li>Lifestyle – 16 weeks – 4 months (highly recommended) £600</li>
            <li>Lifestyle –14 weeks – 3.5 months (standard recommendation) £525</li>
            <li>Lifestyle – 12 weeks – 3 months (minimum recommendation) £450</li>
            <li>Virtual PT sessions - block sessions (minimum 6 upwards only) £60ph</li>
            <li>PT sessions ongoing - block sessions in localised London area (minimum 6 upwards only) £70ph</li>
            <li>Posing sessions – block sessions in localized area (minimum 3 sessions and upwards only) £80ph</li>
        </ul>
        <p>***Remember results may vary from client to client </p>
        <p>Payments are made through stripe. Generally, half up front and the other half at the back end of the time allocation unless otherwise stated or agreed. Payments are to be made on the 1st of the month. All payments are accepted. Be sure to convert the currency value to British sterling pound before sending payment. Please notify me of this (international clients).
        </p>
        <p>
            Once an initial payment has been made a starter pack will be sent to highlight what is required from you as a client and me as a coach. Thereafter based on your requirements I can start working on your exercise diet and nutrition plan.
        </p>
    </div>;

    return (<Section pad="250px" high="1000px" title={title}>{sectionBody()}</Section>);
};
export default Transformations;
