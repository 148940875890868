import React from 'react';
import Paper from '@material-ui/core/Paper';
import FadeIn from "react-fade-in";
import {useInView} from "react-intersection-observer";
import Contact from "./Contact";
import {Card, CardGroup} from "react-bootstrap";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import InstaHome from "./InstaHome";
import {useHistory} from "react-router-dom";

const ContactHome = (props) => {
    const { ref, inView } = useInView({/* Optional options */ threshold: 0, });
    const history = useHistory();

    React.useEffect(() => {
    }, []);

    const goTo = (link) => {
        history.push(link);
    };

    const videoCard = () => <Card className="no-room section meet h800">
        <div
            // style={{backgroundImage: 'url("/images/posing3_2.jpg")',
            style={{backgroundImage: 'url("/images/transformback.jpg")',
            backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
                maxHeight: '1000px'}}>

        <a name="contact"></a>
            <div>
            <FadeIn transitionDuration="1000" delay={0} visible={inView ? true : false} className="full-height">
                <Contact />
            </FadeIn>
            </div>
        </div>
    </Card>;

    const imgCard = (collapse) => <Card className="no-room section">
        <div
            // style={{backgroundImage: 'url("/images/coaching2.jpg")',
            // backgroundSize: 'cover',
            // backgroundPosition: 'center', height: '100%'}}
        >
            <a name="insta"></a>
            <FadeIn transitionDuration="1000" delay={0} visible={inView ? true : false} className="full-height">
                <FadeIn className="animation" transitionDuration="600" visible={inView ? true : false}>
                    <h1 style={{marginTop: '70px'}} >@seancofficial Instagram</h1></FadeIn>
                <InstaHome collapse={collapse}/>
            </FadeIn></div>
    </Card>;

    return (
        <div style={{minWidth: '100%'}} ref={ref}>
            <CardGroup>
                {videoCard()}
                {window.innerWidth < 1000 ? imgCard(true) : imgCard(false)}
            </CardGroup>
        </div>
    );
};
export default ContactHome;
