import React, {useMemo, useContext} from 'react';
import FadeIn from "react-fade-in";
import './BannerHome.css';
import {Button, Accordion, AccordionContext, Card, CardGroup, useAccordionToggle} from "react-bootstrap";
import {useProgressiveImage, useProgressiveImg} from "./useProgressiveImage";
import $ from "jquery";
import './Transformations.css';
import {useInView} from "react-intersection-observer";
import {useHistory} from "react-router-dom";
import ImageCompare from "./ImageCompare";
import VideoEmbed from "./VideoEmbed";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import {IconButton} from "@material-ui/core";

const TransformationHomeSplit = (props) => {
    const [src1, { blur1 }] = useProgressiveImg('/images/meet2.jpg', '/images/meet2_small.jpg'); //width 300px
    const src = useProgressiveImage({ src:'/images/posing.jpg', fallbackSrc: '/images/posing_small.jpg'});
    const { ref, inView } = useInView({/* Optional options */ threshold: 0, });
    const history = useHistory();
    const images = ["/images/before1.jpg", "/images/after1.jpg", "/images/before1.jpg", "/images/after1.jpg", "/images/before2.jpg", "/images/after2.jpg", "/images/before2.jpg", "/images/after2.jpg"];
    const [currdeg, setCurrdeg] = React.useState(0);
    const [page1, setPage1] = React.useState(true);

    React.useEffect(() => {
    }, []);

    const rotate = (direction) => {
        let updatedDeg = 0;
        if(direction == "p"){
            updatedDeg = currdeg + 60;
            setCurrdeg(updatedDeg);
        }
        if(direction == "n"){
            updatedDeg = currdeg - 60;
            setCurrdeg(updatedDeg);
        }
        $(".carousel").css({
            "-webkit-transform": "rotateY("+updatedDeg+"deg)",
            "-moz-transform": "rotateY("+updatedDeg+"deg)",
            "-o-transform": "rotateY("+updatedDeg+"deg)",
            "transform": "rotateY("+updatedDeg+"deg)"
        });
    }

    const goTo = (link) => {
        history.push(link);
    }

    return (
        <div style={{minWidth: '100%'}} ref={ref}>
            {/*minHeight: expanded ? '1100px' : '670px'}}>*/}
            <a name="transformation"></a>
            <CardGroup>
                <Card className="no-room section meet h800">
                    <div className="home-text-split centered-hori">
                    <FadeIn transitionDuration="1000" delay={0} visible={inView ? true : false} className="full-height">
                        <div className="animation">
                            <h1>
                                How will you transform?
                            </h1>
                        </div>
                    </FadeIn>
                        <FadeIn transitionDuration="1000" delay="200" visible={inView ? true : false}>
                            <p>
                                To transform is to change completely the appearance or character of something or someone, especially so that that thing or person is improved. The aim is always to improve. Consistency is key!

                            </p>
                        </FadeIn>
                        <FadeIn transitionDuration="1000" delay="400" visible={inView ? true : false} >Start your journey<KeyboardArrowDownIcon/></FadeIn>
                        <FadeIn transitionDuration="1000" delay="600" visible={inView ? true : false}>
                                <Button
                                    className="button-margin-bottom"
                                    variant="outline-light"
                                    onClick={() => goTo("/transform")} >
                                    READ MORE
                                </Button>
                        </FadeIn>
                        <div className="centered-hori2 button-margin-bottom">
                            <FadeIn transitionDuration="2000" delay="800">
                                <VideoEmbed videoUrl="https://drive.google.com/uc?export=download&id=1-Bn5La1ZMZfkD8ikBWkhtS453UH0Ille" />
                            </FadeIn>
                        </div>
                    </div>
                </Card>
                <Card className="no-room section">
                    <div style={{backgroundImage: 'url("/images/posing.jpg")',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center', height: '100%'}}>
                        <FadeIn transitionDuration="1000" delay={0} visible={inView ? true : false} className="full-height">
                            <div className="center-content space-on-top-more container-body">
                                <div className="caro-container">
                                    {/*style={{left: '50%', position: 'absolute', maxWidth: '50%'}}>*/}
                                    {page1 ? <div className="carousel" style={{height: '100%'}}>
                                        <div className="item a"><ImageCompare after="/images/before1_2.png" before="/images/after1_2.png"/></div>
                                        <div className="item b"><ImageCompare after="/images/before2.png" before="/images/after2.png"/></div>
                                        <div className="item c"><ImageCompare after="/images/before3.png" before="/images/after3.png"/></div>
                                        <div className="item d"><ImageCompare after="/images/before4.png" before="/images/after4.png"/></div>
                                        <div className="item e"><ImageCompare after="/images/before5.png" before="/images/after5.png"/></div>
                                        <div className="item f"><ImageCompare after="/images/before6.png" before="/images/after6.png"/></div>
                                    </div> :
                                    <div className="carousel" style={{height: '100%'}}>
                                     <div className="item a"><ImageCompare after="/images/before7.png" before="/images/after7.png"/></div>
                                        <div className="item b"><ImageCompare after="/images/before8.png" before="/images/after8.png"/></div>
                                        <div className="item c"><ImageCompare after="/images/before9.png" before="/images/after9.png"/></div>
                                        <div className="item d"><ImageCompare after="/images/before10.png" before="/images/after10.png"/></div>
                                        <div className="item e"><ImageCompare before="/images/before2.jpg" after="/images/after2.jpg"/></div>
                                        <div className="item f"><ImageCompare before="/images/before11.png" after="/images/after11.png"/></div>
                                    </div>}
                                </div>
                                <div className="button-container">
                                <Button
                                    className="button-margin-bottom prev space-on-top-more"
                                    variant="outline-light"
                                    onClick={() => {rotate("p")}} >
                                    PREVIOUS
                                </Button>
                                <span> </span>
                                <Button
                                    className="button-margin-bottom next space-on-top-more"
                                    variant="outline-light"
                                    onClick={() => {rotate("n")}} >
                                    NEXT
                                </Button>
                            </div><div className="center-content">
                                <FadeIn transitionDuration="400" visible={inView ? true : false}><IconButton
                                    aria-label="check"
                                    className="icon-button"
                                    onClick={() => {setPage1(!page1)}}>{page1 ?
                                    <i style={{color: "white", fontSize: "small"}}>MORE TRANSFORMATIONS <ArrowForwardIcon/></i> :
                                    <i style={{color: "white", fontSize: "small"}}>PREVIOUS TRANSFORMATIONS <ArrowBackIcon/></i>}
                                </IconButton></FadeIn></div></div>
                        </FadeIn></div>
                </Card>
                </CardGroup>
        </div>
    );
};
export default TransformationHomeSplit;
