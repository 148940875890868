import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import './BannerHome.css';
import Section from "./Section";
import {useHistory} from "react-router-dom";
import {Button} from "react-bootstrap";
import CreditCardIcon from "@material-ui/icons/CreditCard";
const services = require('../assets/services.json');

const Pt = () => {
    const title = "PT (London localised area)";
    const history = useHistory();
    const serviceId = "pt";
    const serviceInfo = services.find(service => service.id === serviceId);

    React.useEffect(() => {
    }, [])

    const goTo = (link) => {
        history.push(link);
    }

    const goToExternal = (link) => {
        window.open(link);
    }

    const sectionBody = () => <div>
        <p>What’s included?</p>
        <p>Sessions are planned in advance. Area of training to be arrange as discussed. </p>
        <p>Sessions last for 1 hour</p>
        {/*<span className="light-blue">Block sessions in localised London area (minimum 6 upwards only)<div><i><strong>£80ph</strong></i></div>*/}
        {/*    <Button className="button-margin-bottom btn-stripe no-marg-left"*/}
        {/*    onClick={() => goToExternal("https://buy.stripe.com/9AQ03s7IR9j39vq9AQ")} >*/}
        {/*                            <img height="20px" src="./images/StripeLogo.png" /> <CreditCardIcon />*/}
        {/*                        </Button><div></div></span>*/}
        {serviceInfo.duration.map(d =>
            <div><span className="light-blue">{d} - <i><strong>{serviceInfo.price}</strong></i></span><br/></div>
        )}
        <Button className="button-margin-bottom btn-paypal no-marg-left"
                onClick={() => goToExternal(serviceInfo.url)} >
            <img height="20px" src="./images/PayPal.png" />
        </Button><div><small><i>All payments taken through paypal</i></small></div><br/>
        <Button
            className="button-margin-bottom"
            variant="outline-light"
            onClick={() => goTo("/contact")} >
            ENQUIRE
        </Button>
    </div>;

    return (<Section high="500px" title={title}>{sectionBody()}</Section>);
};
export default Pt;
