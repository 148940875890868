import React from "react";
import FadeIn from "react-fade-in";
import {useHistory} from "react-router-dom";
import {Button, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import {faAt, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const Footer = (props) => {
    const history = useHistory();
    const goTo = (link) => {
        history.push(link);
    }

    const goToExternal = (link) => {
        window.open(link);
    }

    return (
        <Container className="second-bcol footer-container" style={{height: '100%'}}>
            <Row><img className="logo-font" style={{ marginTop: '10px', height: "100px"}}src="/images/logo.png" /></Row>
            <Row className="wide-footer-links">
                {/*<p style={{marginLeft: '30px'}}>We pride our selfs in producin high quality gym wear.</p>*/}
                {/*<a href="/terms" style={{marginLeft: '60vw',color: 'white'}}>Terms & Conditions</a>*/}
                <a href="/cookiepolicy" style={{marginLeft: '2vw',color: 'white'}}>Cookie policy</a></Row>
            {/*<Row className="small-footer-links"><p style={{marginLeft: '30px'}}>Providing fashionable, functional, high quality gym wear.</p></Row>*/}
            {/*<Row className="small-footer-links center-content"><a href="/orderstatus" style={{color: 'white'}}>Order info</a></Row>*/}
            <Row className="small-footer-links center-content"><a href="/cookiepolicy" style={{color: 'white'}}>Cookie policy</a></Row>
            <Row className="wide-footer-buttons">
                <Col><p className="put-right"><FadeIn delay={1200}>
                    <Button className="footer-button"
                            onClick={() => goToExternal('https://www.instagram.com/seancofficial/')}
                            variant="outline-light">INSTAGRAM <FontAwesomeIcon icon={faInstagram}/></Button></FadeIn></p></Col>
                <Col><p className="center-content"><FadeIn delay={1200}>
                    {/*<Button className="footer-button"*/}
                    {/*        onClick={() => goToExternal('https://www.youtube.com/channel/UCTayALlcRKrmeZszwFHDxKQ')}*/}
                    {/*        variant="outline-light">YOUTUBE <FontAwesomeIcon icon={faYoutube}/></Button>*/}
                    <a href="#home"><Button className="footer-button"
                           variant="outline-light">SIGN UP <FontAwesomeIcon icon={faPencilAlt}/></Button></a></FadeIn></p></Col>
                <Col><p className="put-left"><FadeIn delay={1200}>
                    <a href="#contact"><Button className="footer-button"
                            variant="outline-light">EMAIL <FontAwesomeIcon icon={faAt}/></Button></a></FadeIn></p></Col>
            </Row>
            <Row className="small-footer-buttons">
                <Col><p className="put-right"><FadeIn delay={1200}>
                    <Button className="footer-button"
                            onClick={() => goToExternal('https://www.instagram.com/sean.ldn/')}
                            variant="outline-light"><FontAwesomeIcon icon={faInstagram}/></Button></FadeIn></p></Col>
                <Col><p className="center-content"><FadeIn delay={1200}>
                    {/*<Button className="footer-button"*/}
                    {/*        onClick={() => goToExternal('https://www.youtube.com/channel/UCTayALlcRKrmeZszwFHDxKQ')}*/}
                    {/*        variant="outline-light"><FontAwesomeIcon icon={faYoutube}/></Button>*/}
                    <a href="#home"><Button className="footer-button"
                            variant="outline-light"><FontAwesomeIcon icon={faPencilAlt}/></Button></a></FadeIn></p></Col>
                <Col><p className="put-left"><FadeIn delay={1200}>
                    <Button className="footer-button"
                            onClick={() => goTo("/contact")}
                            variant="outline-light"><FontAwesomeIcon icon={faAt}/></Button></FadeIn></p></Col>
            </Row>
            <Row className="center-content">
                <Typography className="center-me" variant="body2" color="textSecondary" align="center">
                    {'Copyright © '}
                    <Link color="inherit" href="https://material-ui.com/">
                        SC FIT
                    </Link>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography></Row></Container>
    );
};
export default Footer;

// const Footer = () => {
//     const columns = [
//         {title: "Resources",
//             resources: [{ name: "About", link: "/about" },
//                 { name: "Careers", link: "/careers"},
//                 { name: "Contact", link: "/contact"},
//                 { name: "Admin",link: "/admin" }]},
//         {title: "Legal",resources:[{name: "Privacy",link: "/privacy"},
//                 {name: "Terms",link: "/terms"}]},
//         {title: "Visit",resources: [{name: "Locations",link: "/locations"},
//                 {name: "Culture",link: "/culture" }]}];
//
//     return(<SimpleReactFooter style={{width: '100vw'}}
//         description="Quality gymwear for you and your bint"
//         title="PØWA"
//         columns={columns}
//         linkedin="fluffy_cat_on_linkedin"
//         facebook="fluffy_cat_on_fb"
//         twitter="fluffy_cat_on_twitter"
//         instagram="fluffy_cat_live"
//         youtube="UCFt6TSF464J8K82xeA?"
//         pinterest="fluffy_cats_collections"
//         copyright="PØWA"
//         iconColor="black"
//         backgroundColor="indianred"
//         fontColor="black"
//         copyrightColor="darkgrey"
//     />);
// };


