import React from "react";
import {getBaseUrl} from "./Api"

const Checkout = (props) => {
    const discount = 10;
    const quantity = 2;
    const size = "M";
    const name = "big pants";
    const totalPrice = 30;
    const promoDiscount = 0.5;
    const total = 660;
    return (<div className="email-center">
        <p>Hi fdsfds</p>
        <div className="email-top"><em>Thank you for choosing</em></div><br/>
        {/*<a href="http://localhost:3000/"><img className="email-logo" src="./images/sean.png"/></a>*/}
        <div className="email-banner">Order Details</div>
        <table className="email-table">
            <tr><td className="td-name">{quantity} x <a className="email-link" href={getBaseUrl() + "shop"}>{name}</a> - {size}</td><td>£{totalPrice}</td></tr>
            <tr className="text-muted"><td className="td-name"><small>{discount}% discount</small></td><td><small> -£{(discount * totalPrice * 0.01).toFixed(2)}</small></td></tr>
        </table>
        <div className="email-line"></div>
        <table className="email-table">
            <tr><td className="td-name"><i>Promo Discount</i></td><td>-{(promoDiscount*100)}%</td></tr>
        </table>
        <div className="email-line"></div>
        <table className="email-table">
            <tr><th className="td-name">Order Total</th><th>£{total}</th></tr>
        </table>
        <div className="email-banner"></div>
        <div className="wallpaper-container"><img className="wallpaper" src="./images/IMG_8221.jpeg"/>
            <div className="top-left"><small>SHOP</small></div>
            <div className="top-right"><small>YOUTUBE</small></div>
        </div>
    </div>);
};
export default Checkout;


