import React, {useMemo, useContext} from 'react';
import FadeIn from "react-fade-in";
import './BannerHome.css';
import {Button, Accordion, AccordionContext, Card, CardGroup, useAccordionToggle} from "react-bootstrap";
import {useProgressiveImage, useProgressiveImg} from "./useProgressiveImage";
import $ from "jquery";
import './Transformations.css';
import {useInView} from "react-intersection-observer";
import {useHistory} from "react-router-dom";
import ImageCompare from "./ImageCompare";
import VideoEmbed from "./VideoEmbed";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {IconButton} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";


const PosingHome = (props) => {
    const [src1, { blur1 }] = useProgressiveImg('/images/meet2.jpg', '/images/meet2_small.jpg'); //width 300px
    const src = useProgressiveImage({ src:'/images/posing2.jpg', fallbackSrc: '/images/posing2_small.jpg'});
    const { ref, inView } = useInView({/* Optional options */ threshold: 0, });
    const history = useHistory();
    const images = ["/images/before1.jpg", "/images/after1.jpg", "/images/before1.jpg", "/images/after1.jpg", "/images/before2.jpg", "/images/after2.jpg", "/images/before2.jpg", "/images/after2.jpg"];
    const [currdeg, setCurrdeg] = React.useState(0);
    const [page1, setPage1] = React.useState(true);

    React.useEffect(() => {
    }, []);

    const rotate = (direction) => {
        let updatedDeg = 0;
        if(direction == "p"){
            updatedDeg = currdeg + 60;
            setCurrdeg(updatedDeg);
        }
        if(direction == "n"){
            updatedDeg = currdeg - 60;
            setCurrdeg(updatedDeg);
        }
        $(".carousel").css({
            "-webkit-transform": "rotateY("+updatedDeg+"deg)",
            "-moz-transform": "rotateY("+updatedDeg+"deg)",
            "-o-transform": "rotateY("+updatedDeg+"deg)",
            "transform": "rotateY("+updatedDeg+"deg)"
        });
    }

    const goTo = (link) => {
        history.push(link);
    }

    return (
        <div style={{minWidth: '100%'}} ref={ref}>
            <a name="posing"></a>
            <CardGroup>
                <Card className="no-room section meet h800">
                    <div className="home-text-split centered-hori">
                        <FadeIn transitionDuration="1000" delay={0} visible={inView ? true : false} className="full-height">
                            <div className="animation">
                                <h1>
                                    Posing
                                </h1>
                            </div>
                        </FadeIn>
                        <FadeIn transitionDuration="1000" delay="200" visible={inView ? true : false}>
                            <p>
                                ‘To assume a series of positions to be marked in any given bodybuilding competitive division by a judge’. Here you will learn how to pose in your category according to the criteria set by your federation.
                            </p>
                        </FadeIn>
                        {/*<FadeIn transitionDuration="1000" delay="600" visible={inView ? true : false}>*/}
                        {/*    <Button*/}
                        {/*        className="button-margin-bottom"*/}
                        {/*        variant="outline-light"*/}
                        {/*        onClick={() => goTo("/transform")} >*/}
                        {/*        READ MORE*/}
                        {/*    </Button>*/}
                        {/*</FadeIn>*/}
                        <FadeIn transitionDuration="1500" delay="1000">
                            <Button className="button-margin-bottom" variant="outline-light"
                                    onClick={() => goTo("/posing")}>READ MORE</Button>
                        </FadeIn>
                        <div className="centered-hori2 button-margin-bottom">
                            <FadeIn transitionDuration="2000" delay="800">
                                <VideoEmbed videoUrl="https://drive.google.com/uc?export=download&id=12M2lSHALddCfUyipPsz1P_D1924zL6pb" />
                            </FadeIn>
                        </div>
                    </div>
                </Card>
                <Card className="no-room section">
                    <div style={{backgroundImage: 'url("/images/posing2.jpg")',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center', height: '100%'}}>
                        <FadeIn transitionDuration="1000" delay={0} visible={inView ? true : false} className="full-height">
                            <div className="center-content space-on-top-more container-body">
                                <div className="caro-container">
                                    {page1 ? <div className="carousel" style={{height: '100%'}}>
                                        <div className="item a"><ImageCompare before="/images/afterp1.png" after="/images/beforep1.png"/></div>
                                        <div className="item b"><ImageCompare before="/images/afterp2.png" after="/images/beforep2.png"/></div>
                                        <div className="item c"><ImageCompare before="/images/afterp3.png" after="/images/beforep3.png"/></div>
                                        <div className="item d"><ImageCompare before="/images/afterp4.png" after="/images/beforep4.png"/></div>
                                        <div className="item e"><ImageCompare before="/images/afterp5.png" after="/images/beforep5.png"/></div>
                                        <div className="item f"><ImageCompare before="/images/afterp6.png" after="/images/beforep6.png"/></div>
                                    </div> :
                                    <div className="carousel" style={{height: '100%'}}>
                                        <div className="item a"><ImageCompare before="/images/afterp11.png" after="/images/beforep11.png"/></div>
                                        <div className="item b"><ImageCompare before="/images/afterp7.png" after="/images/beforep7.png"/></div>
                                        <div className="item c"><ImageCompare before="/images/afterp8.png" after="/images/beforep8.png"/></div>
                                        <div className="item d"><ImageCompare before="/images/afterp9.png" after="/images/beforep9.png"/></div>
                                        <div className="item e"><ImageCompare before="/images/afterp10.png" after="/images/beforep10.png"/></div>
                                        <div className="item f"><ImageCompare before="/images/afterp12.png" after="/images/beforep12.png"/></div>
                                    </div> }
                                </div>
                                <div className="button-container">
                                    <Button
                                        className="button-margin-bottom prev space-on-top-more"
                                        variant="outline-light"
                                        onClick={() => {rotate("p")}} >
                                        PREVIOUS
                                    </Button>
                                    <span> </span>
                                    <Button
                                        className="button-margin-bottom next space-on-top-more"
                                        variant="outline-light"
                                        onClick={() => {rotate("n")}} >
                                        NEXT
                                    </Button>
                                </div>
                                <div className="center-content">
                                    <FadeIn transitionDuration="400" visible={inView ? true : false}><IconButton
                                        aria-label="check"
                                        className="icon-button"
                                        onClick={() => {setPage1(!page1)}}>{page1 ?
                                        <i style={{color: "white", fontSize: "small"}}>MORE POSING IMAGES <ArrowForwardIcon/></i> :
                                        <i style={{color: "white", fontSize: "small"}}>PREVIOUS POSING IMAGES <ArrowBackIcon/></i>}
                                    </IconButton></FadeIn></div>
                            </div>
                        </FadeIn></div>
                </Card>
            </CardGroup>
        </div>
    );
};
export default PosingHome;
