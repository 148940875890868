import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import './BannerHome.css';
import Section from "./Section";
import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import CreditCardIcon from "@material-ui/icons/CreditCard";
const services = require('../assets/services.json');

const CompPrep = () => {
    const title = "Competition Prep";
    const history = useHistory();
    const serviceId = "online-comp";
    const serviceInfo = services.find(service => service.id === serviceId);

    React.useEffect(() => {
    }, [])

    const goTo = (link) => {
        history.push(link);
    }

    const goToExternal = (link) => {
        window.open(link);
    }

    const sectionBody = () => <div>
        <p>What’s included?</p>
        <ul>
            <li>Competition prep is for those who wish to compete. It isn’t easy and will be demanding at times hard. Sessions will be tweaked after check-ins and current progress. Exercise, diet and nutrition plans are all focused around phases and check-in dates (periodization).</li>
            <li>You will adhere to a calendar stipulating these phases and designed changes. These are back tracked from your final target date (show date).</li>
            <li>As long as you are able to stay in communication and follow the plans made, you will achieve and gain the best possible results for you.</li>
        </ul>
        {/*<span className="light-blue">Off season and full competitive season – online (highly recommended)<div><i><strong>£200 pcm</strong></i></div></span>*/}
        {/*<span className="light-blue">16 weeks – 4 months (highly recommended)<div><i><strong>£800</strong></i></div></span>*/}
        {/*<span className="light-blue">14 weeks – 3.5 months (standard recommendation)<div><i><strong>£700</strong></i></div></span>*/}
        {/*<span className="light-blue">12 weeks – 3 months (minimum recommendation)<div><i><strong>£600</strong></i></div></span>*/}
        {serviceInfo.duration.map(d =>
            <div><span className="light-blue">{d} - <i><strong>{serviceInfo.price}</strong></i></span><br/></div>
        )}
        <Button className="button-margin-bottom btn-paypal no-marg-left"
                onClick={() => goToExternal(serviceInfo.url)} >
            <img height="20px" src="./images/PayPal.png" />
        </Button><div><small><i>All payments taken through paypal</i></small></div><br/>
        <Button
            className="button-margin-bottom"
            variant="outline-light"
            onClick={() => goTo("/contact")} >
            ENQUIRE
        </Button>
    </div>;

    return (<Section high="500px" title={title}>{sectionBody()}</Section>);
};
export default CompPrep;
