import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import {Card, ListGroupItem, ListGroup} from 'react-bootstrap';
import './BannerHome.css';
import Section from "./Section";

const CookiePolicy = () => {
    const title = "Cookie Policy";

    React.useEffect(() => {
    }, [])

    const sectionBody = () => <div>
        <h4>What Are Cookies</h4>
        <p>Most professional websites use cookies, which are tiny files that are downloaded to your computer, to improve your user experience.
        </p>
        <h4>How We Use Cookies</h4>
        <p>We don't currently use any cookies for the operation of the functionality of this site,
            but our third party payment gateway does.</p>
        <h4>Disabling Cookies</h4>
        <ul>
            <li>You can disable the use of cookies by changing settings in your browser (see your browser Help for this).</li>
            <li>This will affect the payment processing on this website,
                and will likely affect the functionality of many other sites you visit, therefore it is recommended that you keep cookies enabled.</li>
        </ul>
        <h4>The Cookies We Set</h4>
        <p>We don't currently set any cookies.</p>
        <h4>Third Party Cookies</h4>
        <p>The payment gateway sets a number of cookies to enable processing of card payments.</p>
    </div>;

    return (<Section high="500px" title={title}>{sectionBody()}</Section>);
};
export default CookiePolicy;


